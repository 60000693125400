import React, { Component } from 'react'
import { Box, Image } from 'grommet'

export class ImageViewer extends Component {
  render () {
    const { src, handleClick } = this.props
    return (
      <Box fill pad='xsmall'>
        <Image fit='contain' src={src} onClick={handleClick} />
      </Box>
    )
  }
}

export default ImageViewer

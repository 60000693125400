import { API } from "aws-amplify";

export const actionTypes = {
  FETCH_PROJECT_LIST: 'FETCH_PROJECT_LIST',
  FETCH_PROJECT_LIST_HAS_ERRORED: 'FETCH_PROJECT_LIST_HAS_ERRORED',
  FETCH_PROJECT_LIST_HAS_ERRORED_RESET: 'FETCH_PROJECT_LIST_HAS_ERRORED_RESET',
  FETCH_PROJECT_LIST_IN_PROGRESS: 'FETCH_PROJECT_LIST_IN_PROGRESS',
  FETCH_PROJECT_LIST_IN_PROGRESS_RESET: 'FETCH_PROJECT_LIST_IN_PROGRESS_RESET',
  FETCH_PROJECT_LIST_SUCCESSFUL: 'FETCH_PROJECT_LIST_SUCCESSFUL',
  UPDATE_VENDOR_SELECTION: 'UPDATE_VENDOR_SELECTION'
}

export function fetchProjectListHasErrored (error) {
  console.log(error)
  return (dispatch) => {
    dispatch(fetchProjectListInProgressReset())
    dispatch({
      type: actionTypes.FETCH_PROJECT_LIST_HAS_ERRORED,
      fetchProjectListHasErrored: true
    })
  }
}

export function fetchProjectListHasErroredReset (error) {
  return {
    type: actionTypes.FETCH_PROJECT_LIST_HAS_ERRORED_RESET,
    fetchProjectListHasErrored: false
  }
}

export function fetchProjectListInProgress () {
  return {
    type: actionTypes.FETCH_PROJECT_LIST_IN_PROGRESS,
    fetchProjectListInProgress: true
  }
}

export function fetchProjectListInProgressReset () {
  return {
    type: actionTypes.FETCH_PROJECT_LIST_IN_PROGRESS_RESET,
    fetchProjectListInProgress: false
  }
}

export function fetchProjectListSuccessful (payload) {
  return (dispatch) => {
    dispatch(fetchProjectListInProgressReset())
    dispatch({
      type: actionTypes.FETCH_PROJECT_LIST_SUCCESSFUL,
      payload
    })
  }
}

export function fetchProjectList () {
  const getProjects = API.get("projects", "projects")

  return (dispatch) => {
    dispatch(fetchProjectListHasErroredReset())
    dispatch(fetchProjectListInProgress())
    return getProjects
      .then(
        success => dispatch(fetchProjectListSuccessful(success)),
        error => dispatch(fetchProjectListHasErrored(error))
      )
  }
}

export function updateVendorSelection (index, locale) {
  return {
    type: actionTypes.UPDATE_VENDOR_SELECTION,
    index,
    locale
  }
}
import React, { Component } from "react";
import { Form, FormField, TextInput, Button, Box, Heading } from "grommet";
import { updateAccountDetails, updatePassword } from '../../actions/account'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PacmanLoader } from 'react-spinners'
import { qlikBrand } from '../../branding'
import { withToastManager } from 'react-toast-notifications';

export class Account extends Component {
  constructor (props) {
    super(props)
    this.updateAccountDetails = this.updateAccountDetails.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
  }

  async updateAccountDetails (value) {
    await this.props.updateAccountDetails(this.props.user.cognitoUser, value.email)
    if (this.props.updatedAccountInformationError) {
      this.props.toastManager.add('There was an error saving your account details', { appearance: 'error', autoDismiss: true})
    } else {
      this.props.toastManager.add('Account details saved successfully', { appearance: 'success', autoDismiss: true });
    }
  }

  async updatePassword(value){
    await this.props.updatePassword(this.props.user.cognitoUser, value.oldPassword, value.password)
    if (this.props.updatedAccountInformationError) {
      this.props.toastManager.add('There was an error saving your password', { appearance: 'error', autoDismiss: true})
    } else {
      this.props.toastManager.add('Password saved successfully', { appearance: 'success', autoDismiss: true });
    }
  }
  validatePassword(field, value){
    if (field !== value.password) {
      return "Both passwords must match"
    }
  }

  saveButtonOrLoading(inProgressProps, label){
    if (inProgressProps){
      return (<PacmanLoader
      loading={inProgressProps}
      sizeUnit='px'
      size={15}
      color={qlikBrand.core.green}
    />)
    } else {
 return (<Button type="submit" label={label} primary={true} />)
    }
  }

  render() {
    return (
      <Box align='stretch' fill='horizontal' >
      <Heading level='1'>Account Details</Heading>
      <Form onSubmit={({ value }) => this.updateAccountDetails(value)} disabled={true}>
        <FormField
            name="email"
            type="email"
            label="Email"
            placeholder={this.props.user.attributes.email}
            required={true}
            component={TextInput}
            />
            {this.saveButtonOrLoading(this.props.accountUpdateInProgress, "Save")}
      </Form>

    <Heading level='3'>Change Password</Heading>
    <Form onSubmit={({ value }) => this.updatePassword(value)}>
      <FormField
          name="oldPassword"
          type="password"
          placeholder="Old Password"
          required={true}
          component={TextInput}
          />
        <FormField
          name="password"
          type="password"
          placeholder="Password"
          required={true}
          component={TextInput}
          />
        <FormField
          name="passwordVerify"
          type="password"
          placeholder="Verify Password"
          required={true}
          component={TextInput}
          validate={this.validatePassword}
          />
          {this.saveButtonOrLoading(this.props.updatePasswordInProgress, "Change Password")}
      </Form>
      </Box>

    );
  }
}

Account.propTypes = {
    updatedAccountInformationError: PropTypes.any,
    accountUpdateInProgress: PropTypes.bool,
    formType: PropTypes.string,
    user: PropTypes.any,
    toastManager: PropTypes.any,
    updatePasswordInProgress: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    updatedAccountInformationError: state.updatedAccountInformationError,
    user: state.user,
    accountUpdateInProgress: state.accountUpdateInProgress,
    updatePasswordInProgress: state.updatePasswordInProgress
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateAccountDetails: (email, password) => dispatch(updateAccountDetails(email, password)),
    updatePassword: (user, oldPassword, password) => dispatch(updatePassword(user, oldPassword, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(Account))
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Text, Form, FormField, Select, Grid } from 'grommet'
import { FormNext, Tasks } from 'grommet-icons'
import { withRouter } from 'react-router-dom'

const renderLocalizedReviewSelection = ({ locales, handleSubmit, project , history, updateVendorSelection}) => {
  handleSubmit = (locale) => {
    history.push(`/viewer/${project._id}/${locale}`)
  }
  return (<Box key={'vendorReview' + project._id}><Form onSubmit={({ value }) => handleSubmit(value.locale)}>
    <Box direction='row-responsive' align='center' gap='small'>
      <Text>Select locale:</Text>
      <Box width='small'>
        <FormField
          name='locale'
          options={project.locales}
          component={Select}
          required={true}
        />
      </Box>
      <Button type='submit' label='Review' icon={<FormNext />} primary reverse />
    </Box>
  </Form></Box>)
}

const renderActions = ({ role, project, history, updateVendorSelection, user, openProjectProgress }) => {
  if ( !user || !project ) {
    return null
  }
  let actions = []
  if (project.status === 'Open') {
    const viewProgress = <Box key={'viewProgress' + project._id}><Button label='View Progress' icon={<Tasks />} onClick={() => openProjectProgress(project.feedback, project.locales, project._id, project.name)} /></Box>
    actions.push(viewProgress)
  }
  if (user.role === 'Coordinators') {
    const review = (
      <Box key={'review' + project._id}>
        <Button
          label='Review SBS'
          onClick={() => {
            history.push(`/viewer/${project._id}`)}
          }
          icon={<FormNext />}
          primary
          reverse
        />
      </Box>
    )
    actions.push(review)
  }
  if (user.role === 'Vendors' && project.status === 'Open') {
    const localizedReview = renderLocalizedReviewSelection({project, history, updateVendorSelection})
    actions.push(localizedReview)
  }
  if (!actions.length) {
    return null
  }
  return (
    <Box direction='row-responsive' justify='end' align='center' gap='small'>
      {actions.map((action) => (
        action
      ))}
    </Box>
  )
}

export class ProjectCard extends Component {
  render () {
    const { project, history, updateVendorSelection, user, openProjectProgress } = this.props
    return (
      <Box width='large' background='light-3' pad='medium' margin='medium' round>
        <Box>
          <Grid
            rows={['xxsmall', 'xxsmall', 'xxsmall']}
            columns={['xsmall', 'flex']}
            gap='xsmall'
            fill
            areas={[
              { name: 'projectLabel', start: [0, 0], end: [0, 0] },
              { name: 'project', start: [1, 0], end: [1, 0] },
              { name: 'statusLabel', start: [0, 1], end: [0, 1] },
              { name: 'status', start: [1, 1], end: [1, 1] },
              { name: 'actions', start: [0, 2], end: [1, 2] }
            ]}
          >
            <Box gridArea='projectLabel' fill>
              <Text weight='bold'>Project</Text>
            </Box>
            <Box gridArea='project' fill>
              <Text>{ project.name }</Text>
            </Box>
            <Box gridArea='statusLabel' fill>
              <Text weight='bold'>Status</Text>
            </Box>
            <Box gridArea='status' fill>
              <Text>{ project.status }</Text>
            </Box>
            <Box gridArea='actions' fill>
              {renderActions({
                role: 'vendor',
                project,
                history,
                updateVendorSelection,
                user,
                openProjectProgress
              })}
            </Box>
          </Grid>
        </Box>
        <Box>
        </Box>
      </Box>
    )
  }
}

ProjectCard.propTypes = {
  history: PropTypes.object.isRequired,
  updateVendorSelection: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  openProjectProgress: PropTypes.func.isRequired
}

export default withRouter(ProjectCard)

import { actionTypes } from '../actions/sbsNavigator'

export function currentIndex (state = 0, action) {
  const { NEXT_IMAGE, PREVIOUS_IMAGE, GO_TO_BEGINNING, GO_TO_INDEX } = actionTypes
  switch (action.type) {
    case NEXT_IMAGE:
      if (state >= (action.max - 1)) {
        return action.max - 1
      }
      return state + 1
    case PREVIOUS_IMAGE:
      if (state <= 0) {
        return 0
      } 
      return state - 1
    case GO_TO_BEGINNING:
      return 0
    case GO_TO_INDEX:
      if (action.userRole === 'Vendors' && !action.feedbackComplete) {
        if (action.index > state) {
          return state
        }
      }
      return action.index
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Box, Button, TextArea, Form, FormField } from 'grommet'
import qlikBrand from '../../branding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export class NegativeFeedback extends Component {
  async handleSubmit (comment){
    const { updateFeedback, currentIndex, viewers, putFeedback, match, nextImage, feedback, numberOfImages } = this.props
    updateFeedback(currentIndex, viewers[0], viewers[1], undefined, comment)
    await putFeedback(match.params.id, currentIndex, feedback[currentIndex][viewers[0]], viewers[0])
    nextImage(numberOfImages)
  }

  render () {
    const { updateFeedback, feedback, currentIndex, viewers, putFeedbackInProgress } = this.props
    let commentValue
    let buttonProps = { label: 'Submit Feedback' }
    if (feedback[currentIndex] && feedback[currentIndex][viewers[0]]) {
      let localeFeedback = feedback[currentIndex][viewers[0]]
      if (localeFeedback.comment) {
        commentValue = {value: feedback[currentIndex][viewers[0]].comment}
        buttonProps.label = 'Update Feedback'
      } else {
        commentValue = {}
      }
    } else {
      commentValue = {}
    }
    if (putFeedbackInProgress) {
      buttonProps = {
        icon: <FontAwesomeIcon icon='spinner' spin/>,
        disabled: true
      }
    }
    return (
      <Box background={qlikBrand.flood.turquoise} pad='xsmall' round>
        <Form onSubmit={({ value }) => this.handleSubmit(value.comment)}>
          <FormField
            name='comment'
            component={TextArea}
            label='How could we improve the translations on this screen?'
            placeholder='Enter feedback'
            onChange={(event) => updateFeedback(currentIndex, viewers[0], viewers[1], undefined, event.target.value)}
            {...commentValue}
          />
          <Box align='center'>
            <Button type='submit' primary={true} {...buttonProps} />
          </Box>
        </Form>
      </Box>
    )
  }
}

export default NegativeFeedback

import { actionTypes } from '../actions/images'

export function imageList (state = [], action) {
  const { FETCH_IMAGE_LIST_SUCCESSFUL } = actionTypes
  switch (action.type) {
    case FETCH_IMAGE_LIST_SUCCESSFUL:
      return action.payload
    default:
      return state
  }
}

export function fetchImageListHasErrored (state = false, action) {
  const { FETCH_IMAGE_LIST_HAS_ERRORED } = actionTypes
  switch (action.type) {
    case FETCH_IMAGE_LIST_HAS_ERRORED:
      return action.fetchImageListHasErrored
    default:
      return state
  }
}

export function fetchImageListInProgress (state = false, action) {
  const { FETCH_IMAGE_LIST_IN_PROGRESS } = actionTypes
  switch (action.type) {
    case FETCH_IMAGE_LIST_IN_PROGRESS:
      return action.fetchImageListInProgress
    default:
      return state
  }
}

export function locales (state = [], action) {
  const { FETCH_IMAGE_LIST_SUCCESSFUL } = actionTypes
  switch (action.type) {
    case FETCH_IMAGE_LIST_SUCCESSFUL:
      if (action.payload.length) {
        return Object.keys(action.payload[0])
      }
      return []
    default:
      return state
  }
}

import { API } from 'aws-amplify'

export const actionTypes = {
  UPDATE_FEEDBACK: 'UPDATE_FEEDBACK',
  PUT_FEEDBACK: 'PUT_FEEDBACK',
  PUT_FEEDBACK_HAS_ERRORED: 'PUT_FEEDBACK_HAS_ERRORED',
  PUT_FEEDBACK_IN_PROGRESS: 'PUT_FEEDBACK_IN_PROGRESS',
  PUT_FEEDBACK_SUCCESFUL: 'PUT_FEEDBACK_SUCCESFUL',
}

export const {
  UPDATE_FEEDBACK,
  PUT_FEEDBACK,
  PUT_FEEDBACK_HAS_ERRORED,
  PUT_FEEDBACK_IN_PROGRESS,
  PUT_FEEDBACK_SUCCESFUL
} = actionTypes

export function updateFeedback (currentIndex, locale, comparedTo, rating, comment) {
  return {
    type: UPDATE_FEEDBACK,
    rating,
    comment,
    currentIndex,
    locale,
    comparedTo
  }
}

export function updateAndPutFeedback (currentIndex, locale, comparedTo, rating, comment, projectId) {
  return (dispatch, getState) => {
    dispatch(updateFeedback(currentIndex, locale, comparedTo, rating, comment))
    const { feedback } = getState()
    dispatch(putFeedback(projectId, currentIndex, feedback[currentIndex][locale], locale))
  }
}

export function putFeedbackHasErrored (error) {
  return (dispatch) => {
    dispatch(putFeedbackInProgress(false))
    dispatch({
      type: PUT_FEEDBACK_HAS_ERRORED,
      putFeedbackHasErrored: true
    })
  }
}

export function putFeedbackInProgress (inProgress) {
  return {
    type: PUT_FEEDBACK_IN_PROGRESS,
    inProgress
  }
}

export function putFeedbackSuccessful (payload) {
  return (dispatch) => {
    dispatch(putFeedbackInProgress(false))
    dispatch({
      type: PUT_FEEDBACK_SUCCESFUL,
      payload
    })
  }
}

export function putFeedback (projectId, imageId, feedback, locale) {
  const updateProject = API.put('projects', `projects/${projectId}/feedback/${imageId}/locale/${locale}`, {
    body: feedback
  });

  return (dispatch) => {
    dispatch(putFeedbackHasErrored(false))
    dispatch(putFeedbackInProgress(true))
    return updateProject
      .then(
        success => dispatch(putFeedbackSuccessful(success)),
        error => dispatch(putFeedbackHasErrored(error))
      )
  }
}
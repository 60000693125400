import { actionTypes } from '../actions/feedbackPanel'

export function feedbackPanelOpen (state = false, action) {
  const { OPEN_FEEDBACK_PANEL, CLOSE_FEEDBACK_PANEL, TOGGLE_FEEDBACK_PANEL } = actionTypes
  switch (action.type) {
    case OPEN_FEEDBACK_PANEL:
      return true
    case CLOSE_FEEDBACK_PANEL:
      return false
    case TOGGLE_FEEDBACK_PANEL:
      return !state
    default:
      return state
  }
}
import React, { Component } from 'react'
import { Box, Grid } from 'grommet'

export class NavigationArea extends Component {
  render () {
    const { expandViewer0, expandViewer1, navigator } = this.props
    return (
      <Grid
        areas={[
          { name: 'viewer0Elements', start: [0, 0], end: [0, 0] },
          { name: 'navigator', start: [1, 0], end: [1, 0] },
          { name: 'viewer1Elements', start: [2, 0], end: [2, 0] }
        ]}
        columns={['small', 'flex', 'small']}
        rows={['flex']}
        fill
      >
        <Box gridArea='viewer0Elements' align='center' justify='center'>{expandViewer0}</Box>
        <Box gridArea='navigator' align='center'>{navigator}</Box>
        <Box gridArea='viewer1Elements' align='center' justify='center'>{expandViewer1}</Box>
      </Grid>
    )
  }
}

export default NavigationArea

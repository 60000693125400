import React, { Component } from 'react'
import { Box, Layer, Button, Text } from 'grommet'
import { FormClose, Tasks } from 'grommet-icons'
import { connect } from 'react-redux'
import { closeProjectProgress } from '../../actions/projectProgress'
import ProjectFeedbackTable from '../../components/ProjectFeedbackTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { fetchImageList } from '../../actions/images'
export class ProjectFeedback extends Component {
  async componentDidMount () {
    const { fetchImageList, projectProgress } = this.props
    await fetchImageList(projectProgress.projectId)
  }

  render () {
    const { closeProjectProgress, imageList, projectProgress, fetchImageListInProgress } = this.props
    return (
      <Layer position='center' modal={true} plain={false} onClickOutside={() => closeProjectProgress()}>
        <Box background='light-2' pad='small' fill>
          {!fetchImageListInProgress && 
            <Box>
              <Box direction='row' align='center' justify='between' fill>
                <Box><Tasks /></Box>
                <Box><Text weight='bold'>{projectProgress.projectName}</Text></Box>
                <Box><Button icon={<FormClose />} onClick={() => closeProjectProgress()} /></Box>
              </Box>
              <ProjectFeedbackTable projectProgress={projectProgress} imageList={imageList} />
            </Box>
          }
          {fetchImageListInProgress && <FontAwesomeIcon icon='spinner' size='10x' spin/>}
        </Box>
      </Layer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    imageList: state.imageList,
    projectProgress: state.projectProgress,
    fetchImageListInProgress: state.fetchImageListInProgress
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeProjectProgress: () => dispatch(closeProjectProgress()),
    fetchImageList: (sbsId) => dispatch(fetchImageList(sbsId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFeedback)

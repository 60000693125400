
import { actionTypes } from '../actions/authenticate'
import jwtDecode from 'jwt-decode';



export function user (state = {
  attributes: {}
}, action) {
    switch (action.type) {
      case actionTypes.LOGIN_SUCCESSFUL:
        return {
          cognitoUser: action.cognitoUser,
          attributes: getUserAttributes(action.userAttributes),
          role: getUserRole(action.cognitoUser)
        }
      default:
        return state
    }
  }

  function getUserAttributes(userAttributes) {
    const attributes = {};
    userAttributes
      .filter(attr => ['email'].includes(attr.Name))
      .forEach(attr => attributes[attr.Name] = attr.Value);
    return attributes
  }

  function getUserRole(cognitoUser) {
    let userInfo = jwtDecode(cognitoUser.signInUserSession.idToken.jwtToken)
    return userInfo["cognito:groups"][0]
  }

  export function authenticationError (state = null, action) {
    switch (action.type) {
      case actionTypes.AUTHENTICATION_HAS_FAILED:
        switch(action.authenticationError.code) {
            default: 
                return 'Email or password incorrect'
        }
       case actionTypes.AUTHENTICATION_IS_SUCCESSFUL:
        return null
      default:
        return state
    }
  }
  
  export function authenticationInProgress (state = false, action) {
    switch (action.type) {
      case actionTypes.AUTHENTICATION_IN_PROGRESS:
        return action.authenticateInProgress
      default:
        return state
    }
  }

  export function isAuthenticated (state = false, action) {
    switch (action.type) {
      case actionTypes.AUTHENTICATION_IS_SUCCESSFUL:
      case actionTypes.LOGOUT_IS_SUCCESSFUL:
        return action.isAuthenticated
      default:
        return state
    }
  }
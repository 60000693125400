
import { actionTypes } from '../actions/account'

export function updatedAccountInformationError (state = null, action) {
    switch(action.type) {
        case actionTypes.UPDATE_USER_ACCOUNT_HAS_FAILED:
            return action.updatedAccountInformationError
        case actionTypes.UPDATE_USER_ACCOUNT_SUCCESSFUL:
            return null    
        default:
            return state
    }
}

export function accountUpdateInProgress (state = false, action) {
    switch(action.type) {
        case actionTypes.UPDATE_USER_ACCOUNT_IN_PROGRESS: 
        case actionTypes.UPDATE_USER_ACCOUNT_HAS_FAILED:
        case actionTypes.UPDATE_USER_ACCOUNT_SUCCESSFUL:
            return action.accountUpdateInProgress
        default:
            return state
    }
}

export function updatePasswordInProgress (state = false, action) {
    switch(action.type) {
        case actionTypes.UPDATE_PASSWORD_IN_PROGRESS: 
        case actionTypes.UPDATE_USER_ACCOUNT_HAS_FAILED:
        case actionTypes.UPDATE_USER_ACCOUNT_SUCCESSFUL:
            return action.updatePasswordInProgress
        default:
            return state
    }
}
import React, { Component } from 'react'
import ImageViewer from '../../components/ImageViewer'
import SbsNavigator from '../../components/SbsNavigator'
import Lightbox from 'react-image-lightbox'
import { Box, Collapsible, Grid, Button } from 'grommet'
import { Expand } from 'grommet-icons'
import { connect } from 'react-redux'
import { openLightbox, closeLightbox } from '../../actions/lightbox'
import { nextImage, previousImage, goToIndex } from '../../actions/sbsNavigator'
import 'react-image-lightbox/style.css'
import qlikBrand from '../../branding'
import NavigationArea from '../../components/NavigationArea'
import { withRouter } from 'react-router-dom'
import { fetchImageList } from '../../actions/images'
import { PacmanLoader } from 'react-spinners'
import { activateViewer } from '../../actions/sbsViewer'
import { setViewerLocale } from '../../actions/viewers'
import { fetchSbsProject } from '../../actions/sbsProject'
import { updateFeedback, putFeedback, updateAndPutFeedback  } from '../../actions/feedback'
import NegativeFeedback from '../../components/NegativeFeedback'
import get from 'lodash/get'

export class SbsViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      locale: null
    }
  }

  openNegativeFeedback = () => {
    const { currentIndex, feedback, viewers } = this.props
    const sentiment = get(feedback, `[${currentIndex}][${viewers[0]}].isGood`, true)
    return !sentiment
  }

  setStartingIndex = async () => {
    const { goToIndex, viewers, feedback, imageList, user } = this.props;
    if (!feedback.length) {
      await goToIndex(0, true, user.role);
      return;
    }
    
    const locale = viewers[0];
    const index = feedback.filter((f) => get(f, locale)).length;
    const statingIndex = index - (imageList.length === index);
    await goToIndex(statingIndex, true, user.role);
  }

  async componentDidMount () {
    const { activateViewer, fetchImageList, fetchSbsProject, match, user, setViewerLocale } = this.props
    activateViewer()
    if (user.role === 'Vendors') {
      setViewerLocale(0, match.params.locale)
    }
    const projectId = match.params.id
    await fetchImageList(projectId)
    await fetchSbsProject(projectId)

    await this.setStartingIndex();
    const locale = this.props.viewers[0];
    this.setState({ mounted: true, locale });
  }

  async componentDidUpdate() {
    const locale = this.props.viewers[0];
    if (this.state.locale && this.state.locale !== locale) {
      this.setState({ locale });
      await this.setStartingIndex();
    }
  }

  render () {
    const {
      lightboxSrc,
      lightboxOpen,
      openLightbox,
      closeLightbox,
      nextImage,
      previousImage,
      goToIndex,
      currentIndex,
      viewers,
      imageList,
      feedback,
      updateFeedback,
      user,
      updateAndPutFeedback,
      putFeedback,
      match,
      putFeedbackInProgress
    } = this.props
    if (!this.state.mounted || !imageList.length) {
      return (<Box align='center'><PacmanLoader
      loading={this.props.fetchProjectListInProgress}
      color={qlikBrand.core.green}
      sizeUnit='px'
      size={50}
    /></Box>)
    }
    const expandViewer0 = <Button icon={<Expand />} label={viewers[0]} color={qlikBrand.flood.wine} round onClick={() => openLightbox(imageList[currentIndex][viewers[0]]['url'])} />
    const expandViewer1 = <Button icon={<Expand />} label={viewers[1]} color={qlikBrand.flood.wine} round onClick={() => openLightbox(imageList[currentIndex][viewers[1]]['url'])} />
    const sbsNavigator = <SbsNavigator
      nextImage={nextImage}
      previousImage={previousImage}
      goToIndex={goToIndex}
      currentIndex={currentIndex}
      numberOfImages={imageList.length}
      feedback={feedback}
      viewers={viewers}
      updateFeedback={updateFeedback}
      user={user}
      updateAndPutFeedback={updateAndPutFeedback}
      match={match}
    />
    return (
      <Box fill>
        <Grid
          areas={[
            { name: 'sbsNavigation', start: [0,0], end: [1,0]},
            { name: 'negativeFeedback', start: [0,1], end: [1,1]},
            { name: 'left', start: [0, 2], end: [0, 2] },
            { name: 'right', start: [1, 2], end: [1, 2] }
          ]}
          columns={['flex', 'flex']}
          rows={['xxsmall','auto', 'flex']}
          gap='xsmall'
          fill
        >
          <Box gridArea='sbsNavigation' margin={{left: 'xsmall', right: 'xsmall', bottom: 'xsmall'}} align='center'>
            <NavigationArea
              expandViewer0={expandViewer0}
              expandViewer1={expandViewer1}
              navigator={sbsNavigator}
            />
          </Box>
          <Box gridArea='negativeFeedback'  margin={{left: 'xsmall', right: 'xsmall'}}>
            <Collapsible open={this.openNegativeFeedback()}>
              <NegativeFeedback
                updateFeedback={updateFeedback}
                feedback={feedback}
                currentIndex={currentIndex}
                viewers={viewers}
                putFeedback={putFeedback}
                nextImage={nextImage}
                match={match}
                putFeedbackInProgress={putFeedbackInProgress}
                numberOfImages={imageList.length}
              />
            </Collapsible>
          </Box>
          <Box gridArea='left' margin={{left: 'xsmall', bottom: 'medium'}} background={qlikBrand.support.grey}>
            <ImageViewer
              src={imageList[currentIndex][viewers[0]]['url']}
              handleClick={() => openLightbox(imageList[currentIndex][viewers[0]]['url'])}
            />
          </Box>
          <Box gridArea='right' margin={{right: 'xsmall', bottom: 'medium'}} background={qlikBrand.support.grey}>
            <ImageViewer
              src={imageList[currentIndex][viewers[1]]['url']}
              handleClick={() => openLightbox(imageList[currentIndex][viewers[1]]['url'])}
            />
          </Box>
        </Grid>
        {lightboxOpen && (<Lightbox mainSrc={lightboxSrc} onCloseRequest={() => closeLightbox()}/>)}
      </Box>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lightboxSrc: state.lightboxSrc,
    lightboxOpen: state.lightboxOpen,
    currentIndex: state.currentIndex,
    viewers: state.viewers,
    imageList: state.imageList,
    feedback: state.feedback,
    user: state.user,
    putFeedbackInProgress: state.putFeedbackInProgress
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openLightbox: (src) => dispatch(openLightbox(src)),
    closeLightbox: () => dispatch(closeLightbox()),
    nextImage: (max) => dispatch(nextImage(max)),
    previousImage: () => dispatch(previousImage()),
    goToIndex: (index, feedbackComplete, userRole) => dispatch(goToIndex(index, feedbackComplete, userRole)),
    fetchImageList: (sbsId) => dispatch(fetchImageList(sbsId)),
    activateViewer: () => dispatch(activateViewer()),
    setViewerLocale: (id, locale) => dispatch(setViewerLocale(id, locale)),
    fetchSbsProject: (id) => dispatch(fetchSbsProject(id)),
    updateFeedback: (currentIndex, locale, comparedTo, rating, comment) => dispatch(updateFeedback(currentIndex, locale, comparedTo, rating, comment)),
    putFeedback: (projectId, imageId, feedback, locale) => dispatch(putFeedback(projectId, imageId, feedback, locale)),
    updateAndPutFeedback: (currentIndex, locale, comparedTo, rating, comment, projectId) => dispatch(updateAndPutFeedback(currentIndex, locale, comparedTo, rating, comment, projectId))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SbsViewer))

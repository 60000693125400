import { Hub } from 'aws-amplify'
import { login } from '../actions/authenticate'

export default class AmplifyBridge {
    constructor(store) {
      this.store = store;
  
      this.handleAuth = this.handleAuth.bind(this);
      Hub.listen('auth', (data) => {
        const { payload } = data;
        this.handleAuth(payload);
      });
    }
  
    handleAuth(payload) {
        switch (payload.event){
            case 'signIn':
            case 'configured':
                this.store.dispatch(login())
                break;
            default:
                return
        }
    }
  }
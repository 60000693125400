import { actionTypes } from '../actions/viewers'
import cloneDeep from 'lodash/cloneDeep'

const { SET_VIEWER_LOCALE, SWAP_LOCALES } = actionTypes

export function viewers (state = ['sv-SE', 'en-US'], action) {
  const { viewerId, locale } = action
  switch (action.type) {
    case SET_VIEWER_LOCALE:
      let viewers = cloneDeep(state)
      viewers[viewerId] = locale
      return viewers
    case SWAP_LOCALES:
      return state.slice().reverse()
    default:
      return state
  }
}
export const actionTypes = {
  OPEN_FEEDBACK_PANEL: 'OPEN_FEEDBACK_PANEL',
  CLOSE_FEEDBACK_PANEL: 'CLOSE_FEEDBACK_PANEL',
  TOGGLE_FEEDBACK_PANEL: 'TOGGLE_FEEDBACK_PANEL'
}

export function openFeedbackPanel () {
  const { OPEN_FEEDBACK_PANEL } = actionTypes
  return {
    type: OPEN_FEEDBACK_PANEL
  }
}

export function closeFeedbackPanel () {
  const { CLOSE_FEEDBACK_PANEL } = actionTypes
  return {
    type: CLOSE_FEEDBACK_PANEL
  }
}

export function toggleFeedbackPanel () {
  const { TOGGLE_FEEDBACK_PANEL } = actionTypes
  return {
    type: TOGGLE_FEEDBACK_PANEL
  }
}
import { API } from "aws-amplify";

export const actionTypes = {
  FETCH_IMAGE_LIST: 'FETCH_IMAGE_LIST',
  FETCH_IMAGE_LIST_HAS_ERRORED: 'FETCH_IMAGE_LIST_HAS_ERRORED',
  FETCH_IMAGE_LIST_IN_PROGRESS: 'FETCH_IMAGE_LIST_IN_PROGRESS',
  FETCH_IMAGE_LIST_SUCCESSFUL: 'FETCH_IMAGE_LIST_SUCCESSFUL'
}

export function fetchImageListHasErrored (error) {
  const { FETCH_IMAGE_LIST_HAS_ERRORED, FETCH_IMAGE_LIST_IN_PROGRESS } = actionTypes
  if (!error) {
    return (dispatch) => {
      dispatch({
        type: FETCH_IMAGE_LIST_HAS_ERRORED,
        fetchImageListHasErrored: false
      })
    }
  } else {
    console.log(error)
    return (dispatch) => {
      dispatch({
        type: FETCH_IMAGE_LIST_IN_PROGRESS,
        fetchImageListInProgress: false
      })
      dispatch({
        type: FETCH_IMAGE_LIST_HAS_ERRORED,
        fetchImageListHasErrored: true
      })
    }
  }
}

export function fetchImageListInProgress (inProgress) {
  const { FETCH_IMAGE_LIST_IN_PROGRESS } = actionTypes
  return {
    type: FETCH_IMAGE_LIST_IN_PROGRESS,
    fetchImageListInProgress: inProgress
  }
}

export function fetchImageListSuccessful (payload) {
  const { FETCH_IMAGE_LIST_SUCCESSFUL } = actionTypes
  return (dispatch) => {
    dispatch(fetchImageListInProgress(false))
    dispatch({
      type: FETCH_IMAGE_LIST_SUCCESSFUL,
      payload
    })
  }
}

export function fetchImageList (sbsId) {
  const getProjectImages = API.get("projects", `projects/${sbsId}/images`)

  return (dispatch) => {
    dispatch(fetchImageListHasErrored(false))
    dispatch(fetchImageListInProgress(true))
    return getProjectImages
      .then(
        success => dispatch(fetchImageListSuccessful(success)),
        error => dispatch(fetchImageListHasErrored(error))
      )
  }
}

// export function loadImagesIntoViewer (viewerId, locale, imageList) {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.LOAD_IMAGES_INTO_VIEWER,
//       viewerId,
//       locale,
//       imageList
//     })
//   }
// }
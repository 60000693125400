import { actionTypes } from '../actions/projectList'
import cloneDeep from 'lodash/cloneDeep'

export function projectList (state = [], action) {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_LIST_SUCCESSFUL:
      return action.payload.data
    case actionTypes.UPDATE_VENDOR_SELECTION:
      let newState = cloneDeep(state)
      newState[action.index].vendorSelect = action.locale
      return newState
    default:
      return state
  }
}

export function fetchProjectListHasErrored (state = false, action) {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_LIST_HAS_ERRORED:
    case actionTypes.FETCH_PROJECT_LIST_HAS_ERRORED_RESET:
      return action.fetchProjectListHasErrored
    default:
      return state
  }
}

export function fetchProjectListInProgress (state = false, action) {
  switch (action.type) {
    case actionTypes.FETCH_PROJECT_LIST_IN_PROGRESS:
    case actionTypes.FETCH_PROJECT_LIST_IN_PROGRESS_RESET:
      return action.fetchProjectListInProgress
    default:
      return state
  }
}

import { Auth } from "aws-amplify";

export const actionTypes = {
    LOGOUT_IS_SUCCESSFUL: 'LOGOUT_IS_SUCCESSFUL',
    LOGOUT_IN_PROGRESS: 'LOGOUT_IN_PROGRESS',
    LOGOUT_HAS_FAILED: 'LOGOUT_HAS_FAILED',
    LOGIN_SUCCESSFUL: 'LOGIN_SUCCESSFUL'
}

export function logoutInProgress(){
  return {
    type: actionTypes.LOGOUT_IN_PROGRESS,
    logoutInProgress: true
  }
}

export function logoutSuccessful(){
  return {
    type: actionTypes.LOGOUT_IS_SUCCESSFUL,
    logoutInProgress: false,
    isAuthenticated: false
  }
}

export function logoutFailed(error){
  return {
    type: actionTypes.LOGOUT_HAS_FAILED,
    logoutInProgress: false,
    logoutError: error
  }
}

export function logout(){
  return (dispatch) => {
    dispatch(logoutInProgress())
    return Auth.signOut()
      .then(
        success => dispatch(logoutSuccessful()),
        error => dispatch(logoutFailed(error))
      )
  }
}

export function login() {
  return dispatch => {
    return Auth.currentAuthenticatedUser()
    .then(
      user => dispatch(getUserInfo(user))
    )
  }
}

export function getUserInfo(user) {
  return dispatch => {
    return Auth.userAttributes(user)
      .then(
        userInfo => dispatch(loginSuccessful(user, userInfo))
      ) 
  }
}

export function loginSuccessful(user, userInfo) {
  return {
    type: actionTypes.LOGIN_SUCCESSFUL,
    cognitoUser: user,
    userAttributes: userInfo
  }
}
import { Auth } from "aws-amplify";

export const actionTypes = {
    UPDATE_USER_ACCOUNT_IN_PROGRESS: 'UPDATE_USER_ACCOUNT_IN_PROGRESS',
    UPDATE_USER_ACCOUNT_SUCCESSFUL: 'UPDATE_USER_ACCOUNT_SUCCESSFUL',
    UPDATE_USER_ACCOUNT_HAS_FAILED: 'UPDATE_USER_ACCOUNT_HAS_FAILED',
    SWITCH_ACCOUNT_FORM: 'SWITCH_ACCOUNT_FORM',
    UPDATE_PASSWORD_IN_PROGRESS: 'UPDATE_PASSWORD_IN_PROGRESS'
}

export function updateAccountDetails (user, email) {
  return (dispatch) => {
    dispatch(updateAccountInProgress())
    return Auth.updateUserAttributes(user, {
      'email': email
    }).then(
        success => dispatch(updateAccountSuccessful(success)),
        error => dispatch(updateAccountHasFailed(error))
      )
  }
}

  
export function updateAccountHasFailed (error) {
  return {
    type: actionTypes.UPDATE_USER_ACCOUNT_HAS_FAILED,
    accountUpdateInProgress: false,
    updatedAccountInformationError: error,
    updatePasswordInProgress: false
  }
}

export function updateAccountSuccessful (cognitoUser) {
  return {
    type: actionTypes.UPDATE_USER_ACCOUNT_SUCCESSFUL,
    accountUpdateInProgress: false,
    updatedAccountInformationError: null,
    updatePasswordInProgress: false
  }
}

export function updateAccountInProgress(){
  return {
    type: actionTypes.UPDATE_USER_ACCOUNT_IN_PROGRESS,
    accountUpdateInProgress: true,
    isAuthenticated: false
  }
}

export function updatePasswordInProgress(){
  return {
    type: actionTypes.UPDATE_PASSWORD_IN_PROGRESS,
    updatePasswordInProgress: true,
    isAuthenticated: false
  }
}

export function updatePassword(user, oldPassword, newPassword){
  return (dispatch => {
    dispatch(updatePasswordInProgress())
    return Auth.changePassword(user, oldPassword, newPassword)
      .then(
        success => dispatch(updateAccountSuccessful(success)),
        error => dispatch(updateAccountHasFailed(error))
      )
    }
  )
}
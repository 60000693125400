import { ACTIVATE_VIEWER, DEACTIVATE_VIEWER } from '../actions/sbsViewer'

export function viewerActive (state = false, action) {
  switch (action.type) {
    case ACTIVATE_VIEWER:
      return true
    case DEACTIVATE_VIEWER:
      return false
    default:
      return state
  }
}
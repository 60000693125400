export const actionTypes = {
  OPEN_PROJECT_PROGRESS: 'OPEN_PROJECT_PROGRESS',
  CLOSE_PROJECT_PROGRESS: 'CLOSE_PROJECT_PROGRESS',
  TOGGLE_PROJECT_PROGRESS: 'TOGGLE_PROJECT_PROGRESS'
}

export const { OPEN_PROJECT_PROGRESS, CLOSE_PROJECT_PROGRESS, TOGGLE_PROJECT_PROGRESS } = actionTypes

export function openProjectProgress (feedback, locales, projectId, projectName) {
  return {
    type: OPEN_PROJECT_PROGRESS,
    feedback,
    locales,
    projectId,
    projectName
  }
}

export function closeProjectProgress () {
  return {
    type: CLOSE_PROJECT_PROGRESS
  }
}

export function toggleProjectProgress () {
  return {
    type: TOGGLE_PROJECT_PROGRESS
  }
}
import { combineReducers } from 'redux'
import { layoutOptionsToggled } from './header'
import { lightboxOpen, lightboxSrc } from './lightbox'
import {
  imageList,
  fetchImageListHasErrored,
  fetchImageListInProgress,
  locales
} from './images'
import { viewers } from './viewers'
import { currentIndex } from './sbsNavigator'
import {
  feedback,
  putFeedbackHasErrored,
  putFeedbackInProgress
} from './feedback'
import {
  projectList,
  fetchProjectListHasErrored,
  fetchProjectListInProgress
} from './projectList'
import { viewerActive } from './sbsViewer'
import { user } from './authenticate'
import { feedbackPanelOpen } from './feedbackPanel'
import {
  accountUpdateInProgress,
  updatedAccountInformationError,
  updatePasswordInProgress
} from './account'
import {
  sbsProject,
  fetchSbsProjectHasErrored,
  fetchSbsProjectInProgress,
  putSbsProjectHasErrored,
  putSbsProjectInProgress,
  reviewLocales
} from './sbsProject'
import { projectProgress } from './projectProgress'

export default combineReducers({
  layoutOptionsToggled,
  lightboxSrc,
  lightboxOpen,
  imageList,
  fetchImageListHasErrored,
  fetchImageListInProgress,
  locales,
  viewers,
  currentIndex,
  feedback,
  projectList,
  fetchProjectListHasErrored,
  fetchProjectListInProgress,
  viewerActive,
  user,
  feedbackPanelOpen,
  sbsProject,
  fetchSbsProjectHasErrored,
  fetchSbsProjectInProgress,
  putSbsProjectHasErrored,
  putSbsProjectInProgress,
  accountUpdateInProgress,
  updatedAccountInformationError,
  updatePasswordInProgress,
  reviewLocales,
  projectProgress,
  putFeedbackHasErrored,
  putFeedbackInProgress
})
import {
  FETCH_SBS_PROJECT_SUCCESFUL,
  PUT_SBS_PROJECT_HAS_ERRORED,
  PUT_SBS_PROJECT_IN_PROGRESS,
  FETCH_SBS_PROJECT_HAS_ERRORED,
  FETCH_SBS_PROJECT_IN_PROGRESS
} from '../actions/sbsProject'

export function sbsProject (state = {}, action) {
  switch (action.type) {
    case FETCH_SBS_PROJECT_SUCCESFUL:
      return action.payload
    default:
      return state
  }
}

export function fetchSbsProjectHasErrored (state = false, action) {
  switch (action.type) {
    case FETCH_SBS_PROJECT_HAS_ERRORED:
      return action.fetchSbsProjectHasErrored
    default:
      return state
  }
}

export function fetchSbsProjectInProgress (state = false, action) {
  switch (action.type) {
    case FETCH_SBS_PROJECT_IN_PROGRESS:
      return action.inProgress
    default:
      return state
  }
}

export function putSbsProjectHasErrored (state = false, action) {
  switch (action.type) {
    case PUT_SBS_PROJECT_HAS_ERRORED:
      return action.putSbsProjectHasErrored
    default:
      return state
  }
}

export function putSbsProjectInProgress (state = false, action) {
  switch (action.type) {
    case PUT_SBS_PROJECT_IN_PROGRESS:
      return action.inProgress
    default:
      return state
  }
}

export function reviewLocales (state = [], action) {
  switch (action.type) {
    case FETCH_SBS_PROJECT_SUCCESFUL:
      if (action.payload.locales.length) {
        return action.payload.locales
      }
      return []
    default:
      return state
  }
}


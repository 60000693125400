import { UPDATE_FEEDBACK, PUT_FEEDBACK_HAS_ERRORED, PUT_FEEDBACK_IN_PROGRESS } from '../actions/feedback'
import { FETCH_SBS_PROJECT_SUCCESFUL } from '../actions/sbsProject'
import cloneDeep from 'lodash/cloneDeep'

export function feedback (state = [], action) {
  const { currentIndex, locale, rating, comment, comparedTo } = action
  switch (action.type) {
    case UPDATE_FEEDBACK:
      let newState = cloneDeep(state)
      newState[currentIndex] = newState[currentIndex] || {}
      newState[currentIndex][locale] = newState[currentIndex][locale] || {}
      if (typeof rating === 'boolean') {
        newState[currentIndex][locale].isGood = rating
      }
      if (typeof comment === 'string' && comment.length >= 0) {
        newState[currentIndex][locale].comment = comment
      }
      if (typeof comparedTo === 'string' && comparedTo.length > 0) {
        newState[currentIndex][locale].comparedTo = comparedTo
      }
      return newState
    case FETCH_SBS_PROJECT_SUCCESFUL:
      return action.payload.feedback
    default:
      return state
  }
}

export function putFeedbackHasErrored (state = false, action) {
  switch (action.type) {
    case PUT_FEEDBACK_HAS_ERRORED:
      return action.putFeedbackHasErrored
    default:
      return state
  }
}

export function putFeedbackInProgress (state = false, action) {
  switch (action.type) {
    case PUT_FEEDBACK_IN_PROGRESS:
      return action.inProgress
    default:
      return state
  }
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, Meter, TableHeader, TableRow } from 'grommet'
import { Like, Dislike } from 'grommet-icons'
import get from 'lodash/get'
import countBy from 'lodash/countBy'
import { qlikBrand } from '../../branding'

const feedbackComplete = (imageFeedback, locale) => {
  const isGood = get(imageFeedback, `[${locale}].isGood`, 'NO_FEEDBACK')
  if (isGood === 'NO_FEEDBACK') {
    return false
  }
  if(isGood) {
    return 'good'
  } else {
    const comment = get(imageFeedback, `[${locale}].comment`, '')
    if (comment.length>0) {
      return 'bad'
    }
  }
  return false
}

const generateRow = (locale, projectProgress, imageList) => {
  const localeFeedback = projectProgress.feedback.map((imageFeedback, index) => {
    return feedbackComplete(imageFeedback, locale)
  })
  const breakdown = countBy(localeFeedback, String)
  const imagesDone = (breakdown.good ? breakdown.good : 0) + (breakdown.bad ? breakdown.bad : 0)
  const imagesRemaining = imageList.length - imagesDone
  let meterValues = []
  if (imagesDone >= 0) {
    meterValues.push(
      {
        label: 'Done',
        value: imagesDone,
        color: qlikBrand.highlight.coral
      }
    )
  }
  if (imagesRemaining >= 0) {
    meterValues.push(
      {
        label: 'Remaining',
        value: imagesRemaining,
        color: qlikBrand.flood.stone
      }
    )
  }
  return (
    <TableRow key={locale}>
      <TableCell scope='row'>
        {locale}
      </TableCell>
      <TableCell>
        {imagesDone}/{imageList.length}
      </TableCell>
      <TableCell>
        <Meter
          type='bar'
          values={meterValues}
          round
        />
      </TableCell>
      <TableCell>
        {breakdown.good ? breakdown.good : 0}
      </TableCell>
      <TableCell>
        {breakdown.bad ? breakdown.bad : 0}
      </TableCell>
    </TableRow>
  )                                                                                   
}

export class ProjectFeedbackTable extends Component {
  render () {
    const { projectProgress, imageList } = this.props
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell scope='col'>
              Locale
            </TableCell>
            <TableCell scope='col'>
              Progress
            </TableCell>
            <TableCell scope='col' />
            <TableCell scope='col'>
              <Like />
            </TableCell>
            <TableCell scope='col'>
              <Dislike />
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {projectProgress.locales.map((locale) => {
            return generateRow(locale, projectProgress, imageList, this.props)
          })}
        </TableBody>
      </Table>
    )
  }
}

ProjectFeedbackTable.propTypes = {
  projectProgress: PropTypes.object.isRequired,
  imageList: PropTypes.array.isRequired
}

export default ProjectFeedbackTable

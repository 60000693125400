import { API } from 'aws-amplify'

export const actionTypes = {
  PUT_SBS_PROJECT: 'PUT_SBS_PROJECT',
  PUT_SBS_PROJECT_HAS_ERRORED: 'PUT_SBS_PROJECT_HAS_ERRORED',
  PUT_SBS_PROJECT_IN_PROGRESS: 'PUT_SBS_PROJECT_IN_PROGRESS',
  PUT_SBS_PROJECT_SUCCESFUL: 'PUT_SBS_PROJECT_SUCCESFUL',
  FETCH_SBS_PROJECT: 'FETCH_SBS_PROJECT',
  FETCH_SBS_PROJECT_HAS_ERRORED: 'FETCH_SBS_PROJECT_HAS_ERRORED',
  FETCH_SBS_PROJECT_IN_PROGRESS: 'FETCH_SBS_PROJECT_IN_PROGRESS',
  FETCH_SBS_PROJECT_SUCCESFUL: 'FETCH_SBS_PROJECT_SUCCESFUL'
}

export const {
  PUT_SBS_PROJECT,
  PUT_SBS_PROJECT_HAS_ERRORED,
  PUT_SBS_PROJECT_IN_PROGRESS,
  PUT_SBS_PROJECT_SUCCESFUL,
  FETCH_SBS_PROJECT,
  FETCH_SBS_PROJECT_HAS_ERRORED,
  FETCH_SBS_PROJECT_IN_PROGRESS,
  FETCH_SBS_PROJECT_SUCCESFUL
} = actionTypes

export function putSbsProjectHasErrored (error) {
  return (dispatch) => {
    dispatch(putSbsProjectInProgress(false))
    dispatch({
      type: PUT_SBS_PROJECT_HAS_ERRORED,
      putSbsProjectHasErrored: true
    })
  }
}

export function putSbsProjectInProgress (inProgress) {
  return {
    type: PUT_SBS_PROJECT_IN_PROGRESS,
    inProgress
  }
}

export function putSbsProjectSuccessful (payload) {
  return (dispatch) => {
    dispatch(putSbsProjectInProgress(false))
    dispatch({
      type: PUT_SBS_PROJECT_SUCCESFUL,
      payload
    })
  }
}

export function putSbsProject (projectId, sbsProject) {
  const updateProject = API.put('projects', 'projects/' + projectId, {
    body: sbsProject
  });

  return (dispatch) => {
    dispatch(putSbsProjectHasErrored(false))
    dispatch(putSbsProjectInProgress(true))
    return updateProject
      .then(
        success => dispatch(putSbsProjectSuccessful(success)),
        error => dispatch(putSbsProjectHasErrored(error))
      )
  }
}

export function fetchSbsProjectHasErrored (error) {
  return (dispatch) => {
    dispatch(fetchSbsProjectInProgress(false))
    dispatch({
      type: FETCH_SBS_PROJECT_HAS_ERRORED,
      fetchSbsProjectHasErrored: true
    })
  }
}

export function fetchSbsProjectInProgress (inProgress) {
  return {
    type: FETCH_SBS_PROJECT_IN_PROGRESS,
    inProgress
  }
}

export function fetchSbsProjectSuccessful (payload) {
  return (dispatch) => {
    dispatch(fetchSbsProjectInProgress(false))
    dispatch({
      type: FETCH_SBS_PROJECT_SUCCESFUL,
      payload
    })
  }
}

export function fetchSbsProject (projectId) {
  const getProject = API.get('projects', 'projects/' + projectId)

  return (dispatch) => {
    dispatch(fetchSbsProjectHasErrored(false))
    dispatch(fetchSbsProjectInProgress(true))
    return getProject
      .then(
        success => dispatch(fetchSbsProjectSuccessful(success)),
        error => dispatch(fetchSbsProjectHasErrored(error))
      )
  }
}
export const actionTypes = {
  ACTIVATE_VIEWER: 'ACTIVATE_VIEWER',
  DEACTIVATE_VIEWER: 'DEACTIVATE_VIEWER'
}

export const { ACTIVATE_VIEWER, DEACTIVATE_VIEWER } = actionTypes

export function activateViewer () {
  return {
    type: ACTIVATE_VIEWER
  }
}

export function deactivateViewer () {
  return {
    type: DEACTIVATE_VIEWER
  }
}
import { OPEN_PROJECT_PROGRESS, CLOSE_PROJECT_PROGRESS } from '../actions/projectProgress'

export function projectProgress (state = {open: false, feedback: {}, locales: [], projectId: '', projectName: ''}, action) {
  switch (action.type) {
    case OPEN_PROJECT_PROGRESS:
      return {open: true, feedback: action.feedback, locales: action.locales, projectId: action.projectId, projectName: action.projectName}
    case CLOSE_PROJECT_PROGRESS:
      return {open: false, feedback: {}, locales: [], projectId: '', projectName: ''}
    default:
      return state
  }
}
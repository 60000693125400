import { actionTypes } from '../actions/lightbox'

export function lightboxOpen (state = false, action) {
  const { OPEN_LIGHTBOX, CLOSE_LIGHTBOX } = actionTypes
  switch (action.type) {
    case OPEN_LIGHTBOX:
      return action.lightboxOpen
    case CLOSE_LIGHTBOX:
      return action.lightboxOpen
    default:
      return state
  }
}

export function lightboxSrc (state = '', action) {
  const { OPEN_LIGHTBOX, CLOSE_LIGHTBOX } = actionTypes
  switch (action.type) {
    case OPEN_LIGHTBOX:
      return action.src
    case CLOSE_LIGHTBOX:
      return ''
    default:
      return state
  }
}
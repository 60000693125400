export const actionTypes = {
  SET_VIEWER_LOCALE: 'SET_VIEWER_LOCALE',
  SWAP_LOCALES: 'SWAP_LOCALES'
}

const { SET_VIEWER_LOCALE, SWAP_LOCALES } = actionTypes

export function setViewerLocale (viewerId, locale) {
  return {
    type: SET_VIEWER_LOCALE,
    viewerId,
    locale
  }
}

export function swapLocales () {
  return {
    type: SWAP_LOCALES
  }
}
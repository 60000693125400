import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Heading,} from 'grommet'
import { PacmanLoader } from 'react-spinners'
import { qlikBrand } from '../../branding'
import PropTypes from 'prop-types'
import { fetchProjectList, updateVendorSelection } from '../../actions/projectList'
import ProjectCard from '../../components/ProjectCard'
import { deactivateViewer } from '../../actions/sbsViewer'
import cloneDeep from 'lodash/cloneDeep'
import { openProjectProgress } from '../../actions/projectProgress'
import ProjectFeedback from '../ProjectFeedback'

const renderBugfixProjects = ({ projects, fetchProjectListInProgress, updateVendorSelection, user, openProjectProgress, listProps }) => {
  if (fetchProjectListInProgress) {
    return null
  }
  if (!projects.length) {
    return (
      <Box align='center'>No projects!</Box>
    )
  } else {
    return (
      <Box align='center'>
        {projects.map((project, index) => {
          let componentProject = cloneDeep(project)
          componentProject.index = index
          return (
          <ProjectCard
            key={project._id}
            project={componentProject}
            updateVendorSelection={updateVendorSelection}
            user={user}
            openProjectProgress={openProjectProgress}
            listProps={listProps}
          />)
        })}
      </Box>
    )
  }
}

export class ProjectList extends Component {
  async componentDidMount () {
    this.props.deactivateViewer()
    await this.props.fetchProjectList()
  }
  render () {
    const {
      projectList,
      fetchProjectListInProgress,
      updateVendorSelection,
      user,
      projectProgress,
      openProjectProgress
    } = this.props
    return (
      <Box align='stretch' fill='horizontal' jusify='center'>
        <Box align='center'><Heading level='2'>Side By Side Projects</Heading></Box>
        <Box align='center'><PacmanLoader
          loading={fetchProjectListInProgress}
          color={qlikBrand.core.green}
          sizeUnit='px'
          size={50}
        /></Box>
        <Box>
          {renderBugfixProjects({
            projects: projectList,
            fetchProjectListInProgress,
            updateVendorSelection,
            user,
            openProjectProgress
          })}
        </Box>
        {projectProgress.open && <ProjectFeedback />}
      </Box>
    )
  }
}

ProjectList.propTypes = {
  fetchProjectList: PropTypes.func.isRequired,
  projectList: PropTypes.array.isRequired,
  fetchProjectListInProgress: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    projectList: state.projectList,
    fetchProjectListHasErrored: state.fetchProjectListHasErrored,
    fetchProjectListInProgress: state.fetchProjectListInProgress,
    user: state.user,
    projectProgress: state.projectProgress
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectList: () => dispatch(fetchProjectList()),
    deactivateViewer: () => dispatch(deactivateViewer()),
    updateVendorSelection: (index, locale) => dispatch(updateVendorSelection(index, locale)),
    openProjectProgress: (feedback, locales, projectId, projectName) => dispatch(openProjectProgress(feedback, locales, projectId, projectName))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList)


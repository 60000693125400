import React, { Component } from 'react'
import { Box, Button, Grid, Select, Text } from 'grommet'
import { Menu } from 'grommet-icons'
import { connect } from 'react-redux'
import { toggleLayout } from '../../actions/header'
import { setViewerLocale, swapLocales } from '../../actions/viewers'
import { qlikBrand } from '../../branding'
import { withRouter } from 'react-router-dom'
import { logout } from '../../actions/authenticate'

export const AppBar = (props) => (
  <Box
    animation='fadeIn'
    tag='header'
    direction='row'
    align='center'
    background={qlikBrand.flood.turquoise}
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '10' }}
    {...props}
  />
)

const viewerHeader = ({ locales, viewers, setViewerLocale, user, history, currentIndex, reviewLocales }) => {
  let reviewLocale = null
  if (user.role === 'Vendors') {
    reviewLocale = <Text weight='bold' color={qlikBrand.highlight.peridot}>{viewers[0]}</Text>
  }
  if (user.role === 'Coordinators') {
    reviewLocale = (
      <Select
        options={reviewLocales}
        value={viewers[0]}
        onChange={event => setViewerLocale(0, event.value)}
      />
    )
  }
  return (
    <Grid
      areas={[
        { name: 'buttons', start: [0, 0], end: [0, 0] },
        { name: 'locales', start: [1, 0], end: [1, 0] }
      ]}
      columns={['flex', 'flex']}
      rows={['flex']}
      fill
    >
        <Box gridArea='buttons' direction='row' gap='small' fill>
          <Button icon={<Menu />} label='Projects' onClick={() => history.push('/')} />
        </Box>
        <Box gridArea='locales' direction='row' justify='end' fill>
          <Box direction='row' gap='small' fill>
            <Box justify='center' fill><Text>Currently reviewing:</Text></Box>
            <Box justify='center' fill>{reviewLocale}</Box>
            <Box justify='center' fill><Text>Compare to:</Text></Box>
            <Box justify='center' fill>
              <Select
                options={locales}
                value={viewers[1]}
                onChange={event => setViewerLocale(1, event.value)}
              />
            </Box>
          </Box>
        </Box>
    </Grid>
  )
}
const navHeader = (history, logout, location) => {
  const onProjectPage = location.pathname === '/'
  const onAccountPage = location.pathname === '/account'
  return (
    <Grid
      areas={[
        { name: 'left', start: [0, 0], end: [0, 0] },
        { name: 'middle', start: [1, 0], end: [1, 0] },
        { name: 'right', start: [2, 0], end: [2, 0] }
      ]}
      columns={['flex', 'flex', 'flex']}
      rows={['flex']}
      fill
    >
      <Box gridArea='left' direction='row' gap='small' fill>
        {!onProjectPage && <Button label='Projects' icon={<Menu />} level='3' margin='none' onClick={() => { history.push('/') }} />}
      </Box>
      <Box gridArea='middle' direction='row' align='center' justify='center' fill>
        <Text weight='bold' size='large'>LOCMAN</Text>
      </Box>
      <Box gridArea='right' direction='row'  justify='end' fill>
        <Box direction='row' gap='small'>
          <Box>{!onAccountPage && <Button  label='Account' onClick={()=>history.push('/account')}/>}</Box>
          <Box><Button label='Logout' onClick={()=>logout()}/></Box>
        </Box>
      </Box>
  </Grid>
)}

export class Header extends Component {
  render () {
    const {
      locales,
      viewers,
      setViewerLocale,
      viewerActive,
      location,
      user,
      history,
      currentIndex,
      logout,
      reviewLocales
    } = this.props
    let appBar
    if (viewerActive) {
      appBar = viewerHeader({
        locales,
        viewers,
        setViewerLocale,
        user,
        history,
        currentIndex,
        reviewLocales
      })
    } else {
      appBar = navHeader(history, logout, location)
    }
    return (
      <AppBar>
        {appBar}
      </AppBar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locales: state.locales,
    viewers: state.viewers,
    currentIndex: state.currentIndex,
    viewerActive: state.viewerActive,
    user: state.user,
    reviewLocales: state.reviewLocales
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLayout: () => dispatch(toggleLayout()),
    setViewerLocale: (viewerId, locale) => dispatch(setViewerLocale(viewerId, locale)),
    swapLocales: () => dispatch(swapLocales()),
    logout: () => dispatch(logout())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))

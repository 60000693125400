export const actionTypes = {
  NEXT_IMAGE: 'NEXT_IMAGE',
  PREVIOUS_IMAGE: 'PREVIOUS_IMAGE',
  GO_TO_BEGINNING: 'GO_TO_BEGINNING',
  GO_TO_INDEX: 'GO_TO_INDEX'
}

export function nextImage (max) {
  const { NEXT_IMAGE } = actionTypes
  return {
    type: NEXT_IMAGE,
    max
  }
}

export function previousImage () {
  const { PREVIOUS_IMAGE } = actionTypes
  return {
    type: PREVIOUS_IMAGE
  }
}

export function goToBeginning () {
  const { GO_TO_BEGINNING } = actionTypes
  return {
    type: GO_TO_BEGINNING
  }
}

export function goToIndex (index, feedbackComplete, userRole) {
  const { GO_TO_INDEX } = actionTypes
  return {
    type: GO_TO_INDEX,
    index,
    feedbackComplete,
    userRole
  }
}
import { actionTypes } from '../actions/header'

export function layoutOptionsToggled (state = false, action) {
  const { TOGGLE_LAYOUT_OPTIONS } = actionTypes
  switch (action.type) {
    case TOGGLE_LAYOUT_OPTIONS:
      return !state
    default:
      return state
  }
}
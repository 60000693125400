import React, { Component } from 'react'
import { Box, Button, RangeInput } from 'grommet'
import { Dislike, Like, Next, Previous } from 'grommet-icons'
import { qlikBrand } from '../../branding'
import get from 'lodash/get'

export class SbsNavigator extends Component {
  feedbackComplete = () => {
    const { currentIndex, feedback, viewers } = this.props
    const sentiment = get(feedback, `[${currentIndex}][${viewers[0]}].isGood`, '-1')
    if (sentiment === '-1') {
      return false
    }
    let commentExists = false
    if (!sentiment) {
      const comment = get(feedback, `[${currentIndex}][${viewers[0]}].comment`, '')
      commentExists = comment.length > 0
      if (commentExists) {
        return true
      }
    } else {
      return true
    }
    return false
  }

  handleLike () {
    const { currentIndex, viewers, nextImage, updateAndPutFeedback, match, numberOfImages } = this.props
    updateAndPutFeedback(currentIndex, viewers[0], viewers[1], true, undefined, match.params.id)
    nextImage(numberOfImages)
  }

  handleDislike = () => {
    const { updateFeedback, currentIndex, viewers } = this.props
    updateFeedback(currentIndex, viewers[0], viewers[1], false, undefined)
  }

  render () {
    const { nextImage, previousImage, goToIndex, currentIndex, numberOfImages, feedback, viewers, user } = this.props
    const lastIndex = numberOfImages - 1
    let incrementDisabled = currentIndex >= lastIndex
    let decrementDisabled = currentIndex <= 0
    if (user.role === 'Vendors') {
      incrementDisabled = !this.feedbackComplete() || currentIndex >= lastIndex
    }
    let dislikeStyle, likeStyle
    if (feedback[currentIndex] && feedback[currentIndex][viewers[0]]) {
      let localeFeedback = feedback[currentIndex][viewers[0]]
      if (typeof localeFeedback.isGood === 'boolean' && localeFeedback.isGood) {
        dislikeStyle = {}
        likeStyle = {color: 'status-ok'}
      } else if (typeof localeFeedback.isGood === 'boolean' && !localeFeedback.isGood) {
        dislikeStyle = {color: 'status-critical'}
        likeStyle = {}
      } else {
        dislikeStyle = {}
        likeStyle = {}
      }
    } else {
      dislikeStyle = {}
      likeStyle = {}
    }
    return (
      <Box direction='row' justify='between' gap='medium' fill>
        <Box direction='row' justify='center' align='center' round border width='small'>
            <Button icon={<Dislike {...dislikeStyle} />} onClick={() => this.handleDislike()}  />
            <Button icon={<Like {...likeStyle} />} onClick={() => this.handleLike()} />
        </Box>
        <Box direction='row' background={qlikBrand.flood.stone} round align='center' fill>
          <Button icon={<Previous />} onClick={() => previousImage()} disabled={decrementDisabled} />
          <RangeInput
            value={currentIndex}
            min={0}
            max={lastIndex}
            step={1}
            onChange={event => goToIndex(parseInt(event.target.value), this.feedbackComplete(), user.role)}
          />
          <Button icon={<Next />} onClick={() => nextImage(numberOfImages)} disabled={incrementDisabled} />
        </Box>
      </Box>
    )
  }
}

export default SbsNavigator

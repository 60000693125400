import React, { Component } from 'react'
import { Route, Switch } from 'react-router'
import SbsViewer from '../SbsViewer'
import ProjectList from '../ProjectList'
import { connect } from 'react-redux'
import Account from '../Account'

export class Main extends Component {
  render () {
    return (
      <main>
        <Switch>
          <Route exact path='/' component={ProjectList} />
          <Route exact path='/viewer/:id' component={SbsViewer} />
          <Route exact path='/viewer/:id/:locale' component={SbsViewer} />
          <Route exact path='/account' component={Account} />
          <Route path="*" component={ProjectList} />
        </Switch>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(Main)

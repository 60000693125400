import React, { Component } from 'react'
import { Box, Grommet, ResponsiveContext, Grid } from 'grommet'
import Header from './containers/Header'
import Main from './containers/Main'
import { theme } from './branding'
import { ConfirmSignIn, ForgotPassword, RequireNewPassword, SignIn, VerifyContact, withAuthenticator } from 'aws-amplify-react'
import { ToastProvider } from 'react-toast-notifications'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner)

export class App extends Component {
  render () {
    return (
      <ToastProvider
        placement="bottom-right"
      >
        <Grommet theme={theme} full>
          <ResponsiveContext.Consumer>
            {size => (
              <Grid
                rows={['xsmall', 'flex']}
                columns={['flex']}
                fill
                areas={[
                  { name: 'header', start: [0, 0], end: [0, 0] },
                  { name: 'main', start: [0, 1], end: [0, 1] }
                ]}
              >
                <Box gridArea='header'>
                  <Header />
                </Box>
                <Box gridArea='main' align='center' fill>
                  <Main />
                </Box>
              </Grid>)
            }
          </ResponsiveContext.Consumer>
        </Grommet>
      </ToastProvider>
    )
  }
}

export default withAuthenticator(App, false, [
  <SignIn/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <ForgotPassword/>,
  <RequireNewPassword />
    ]
)

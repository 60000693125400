export const actionTypes = {
  OPEN_LIGHTBOX: 'OPEN_LIGHTBOX',
  CLOSE_LIGHTBOX: 'CLOSE_LIGHTBOX'
}

export function openLightbox ( src ) {
  const { OPEN_LIGHTBOX } = actionTypes
  return {
    type: OPEN_LIGHTBOX,
    lightboxOpen: true,
    src
  }
}

export function closeLightbox () {
  const { CLOSE_LIGHTBOX } = actionTypes
  return {
    type: CLOSE_LIGHTBOX,
    lightboxOpen: false
  }
}